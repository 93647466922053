var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"users","fluid":"","tag":"section"}},[_c('alert-notification',{attrs:{"message":_vm.alert}}),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('admin.sub.users.title'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('admin.sub.users.table.search'),"single-line":"","hide-details":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.table.headers,"options":_vm.table.options,"items":_vm.users,"search":_vm.table.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.location",fn:function(ref){
var item = ref.item;
return [(item.location)?_c('span',[_vm._v(_vm._s(item.location.city))]):_vm._e()]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatRole(item.role)))])]}},{key:"item.setting.vehicle",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t('userSettings.settings.vehicle.values.' + String(item.setting.vehicle) + '.name')))])]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('a',{attrs:{"href":'tel:' + _vm.phoneInt(item.phoneNumber),"target":"_blank"}},[_vm._v(_vm._s(_vm.displayPhone(item.phoneNumber)))])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }