<template>
  <v-container id="users" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card>
      <v-card-title>
        {{ $t('admin.sub.users.title') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('admin.sub.users.table.search')" single-line hide-details />
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="users" :search="table.search" :loading="loading">
        <template #[`item.location`]="{ item }">
          <span v-if="item.location">{{ item.location.city }}</span>
        </template>
        <template #[`item.role`]="{ item }">
          <span>{{ formatRole(item.role) }}</span>
        </template>
        <template #[`item.setting.vehicle`]="{ item }">
          <span>{{ $t('userSettings.settings.vehicle.values.' + String(item.setting.vehicle) + '.name') }}</span>
        </template>
        <template #[`item.phoneNumber`]="{ item }">
          <span><a :href="'tel:' + phoneInt(item.phoneNumber)" target="_blank">{{ displayPhone(item.phoneNumber) }}</a></span>
        </template>
        <template #[`item.createdAt`]="{ item }">
          <span>{{ formatDate(item.createdAt) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';
  import { handleRequestError } from '@/services/common/Http';
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';

  export default {
    name: 'MetaUsers',
    components: { AlertNotification },
    data () {
      return {
        alert: '',
        createDriverForm: false,
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('admin.sub.users.table.column.id'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'id'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.location'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'location'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.username'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'username'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.firstName'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'firstName'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.lastName'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'lastName'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.role'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'role'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.phone'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'phoneNumber'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.vehicle'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'setting.vehicle'
            },
            {
              text: this.$i18n.t('admin.sub.users.table.column.createdAt'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'createdAt'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: ['firstName'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        users: []
      };
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      displayPhone: displayPhone,
      phoneInt: phoneInt,
      formatRole (role) {
        return this.$i18n.t('admin.sub.users.role.' + role);
      },
      formatDate (date) {
        return DateTime.fromISO(date, { setZone: true }).toLocaleString({ locale: 'fr-fr' });
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'users/staff');
          if (response.data !== undefined && response.data.users !== undefined) {
            this.users = response.data.users;
          }
          this.alert = '';
        } catch (error) {
          this.alert = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('admin.error.fail'));
        }
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
      }
    }
  };
</script>
